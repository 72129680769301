import { ButtonS, H2 } from '@a3/chameleon/src/components/basic/Typography';
import cn from 'classnames';
import useAnalytics from 'hooks/useAnalytics';
import type { FC } from 'react';
import { useState } from 'react';

import { gtmEvents } from 'constants/gtmEvent';
import pagesUrls from 'constants/pagesUrls';

import FaqWrapper from 'components/Faq/FaqWrapper';
import { faqCardData, faqCardDataAll } from 'components/Faq/data';
import type { FaqTypes } from 'components/Faq/types';
import { analyticsEvent } from 'components/Faq/types';
import ContentContainer from 'components/basic/ContentContainer';

import s from './Faq.module.scss';

const Faq: FC<FaqTypes> = ({
  downloadHref = pagesUrls.downloadStore,
  analyticsEvt = analyticsEvent,
  className,
  ...restProps
}) => {
  const { clickAnalytics } = useAnalytics();
  const [buttonText, setButtonText] = useState('Посмотреть все вопросы');
  const [isExpanded, setIsExpanded] = useState(false);
  const [faqData, setFaqData] = useState(faqCardData);

  const buttonHandler = (): void => {
    if (isExpanded) {
      setIsExpanded(false);
      setButtonText('Посмотреть все вопросы');
      setFaqData(faqCardData);
    } else {
      setIsExpanded(true);
      setButtonText('Свернуть все вопросы');
      setFaqData(faqCardDataAll);
    }

    clickAnalytics(gtmEvents.clickAllQuestionsFaq);
  };

  return (
    <div className={cn(s.wrapper, className)} {...restProps} id="js-faq">
      <ContentContainer className={cn(s.container, 'grid')}>
        <H2 className={cn(s.title, 'g-col-xss-4 g-col-s-8')}>
          Нас часто спрашивают
        </H2>
        <FaqWrapper
          downloadHref={downloadHref}
          analyticsEvent={analyticsEvt}
          data={faqData}
          className="g-col-12"
        />

        <ButtonS
          className={cn(s.button, 'g-col-2 g-start-11')}
          onClick={buttonHandler}
        >
          {buttonText}
        </ButtonS>
      </ContentContainer>
    </div>
  );
};

export default Faq;
