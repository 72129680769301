import type { DivProps } from 'app.types';
import type { ReactNode } from 'react';

import type { ClickEvent } from 'constants/gtmEvent';
import { gtmEvents } from 'constants/gtmEvent';

export interface FaqTypes extends DivProps {
  downloadHref?: string;
  analyticsEvt?: ClickEvent;
}

export interface FaqCardTypes extends DivProps {
  data: FaqCardDataTypes[];
  downloadHref: string;
  analyticsEvent: ClickEvent;
}

export interface FaqCardDataTypes {
  title: string;
  body: string | ReactNode;
}

export const classBlock = '.js-download-application';
export const analyticsEvent = gtmEvents.clickDownloadStoreFaq;
