import Button from '@a3/chameleon/src/components/basic/Button';
import { Subtitle2 } from '@a3/chameleon/src/components/basic/Typography';
import {
  useFloating,
  arrow,
  offset,
  autoUpdate,
  flip,
  FloatingArrow,
  shift,
} from '@floating-ui/react';
import useAnalytics from 'hooks/useAnalytics';
import useMobile from 'hooks/useMobile';
import { useRouter } from 'next/router';
import React, { useRef, useState, useCallback } from 'react';
import type { FC, ReactNode } from 'react';

import type { ClickEvent } from 'constants/gtmEvent';
import pagesUrls from 'constants/pagesUrls';

import s from 'components/complex/DropdownClick/DropdownClick.module.scss';

import { handleScrollAndToggle } from 'utils/handleScrollAndToggle';

interface DropdownClickProps {
  className?: string;
  children: ReactNode;
  classBlock: string;
  analyticsEvent: ClickEvent;
  downloadHref?: string;
}

const DropdownClick: FC<DropdownClickProps> = ({
  className,
  children,
  classBlock,
  analyticsEvent,
  downloadHref = pagesUrls.downloadStore,
}) => {
  const router = useRouter();
  const { clickAnalytics } = useAnalytics();
  const { isMobile } = useMobile({ width: 768 });
  const arrowRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { x, y, refs, strategy, context } = useFloating({
    placement: 'bottom',
    middleware: [offset(10), shift(), flip(), arrow({ element: arrowRef })],
    whileElementsMounted: autoUpdate,
  });
  const closeMenu = useCallback(
    (e: MouseEvent | Event) => {
      if (
        refs.reference.current instanceof HTMLElement &&
        !refs.reference.current.contains(e.target as Node)
      ) {
        setIsOpen(false);
        document.removeEventListener('click', closeMenu);
      }
    },
    [refs.reference],
  );

  function toggleDrop(): void {
    if (!isOpen) {
      document.addEventListener('click', closeMenu);
      setIsOpen(true);
    } else {
      setIsOpen(false);
      document.removeEventListener('click', closeMenu);
    }
  }

  return (
    <div className={className}>
      <div ref={refs.setReference} onClick={toggleDrop}>
        {children}
        {isOpen && (
          <div
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y,
              left: x,
              zIndex: 2,
            }}
          >
            <div className={s.dropdownContent}>
              <Subtitle2>Функция доступна в приложении</Subtitle2>
              <Button
                size="small"
                look="danger"
                className={s.button}
                href={isMobile ? downloadHref : undefined}
                onClick={(): void => {
                  if (!isMobile) {
                    handleScrollAndToggle({
                      router,
                      clickAnalytics,
                      classBlock,
                      analyticsEvent,
                    });
                  }
                }}
              >
                Скачать
              </Button>
            </div>
            <FloatingArrow
              ref={arrowRef}
              context={context}
              width={20}
              height={12}
              fill="#f2f6fa"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DropdownClick;
