export enum ImagesPathEnum {
  qrCode = '/img/DownloadApplication/QR.png',
  qrCodeTestB = '/img/DownloadApplication/B/QR.png',
  qrCodeTestC = '/img/DownloadApplication/C/QR.png',
  rustore = '/img/DownloadApplication/rustore.svg',
  nashstore = '/img/DownloadApplication/nashstore.svg',
  ring = '/img/DownloadApplication/ring.svg',
  phone = '/img/DownloadApplication/phone.png',
  notification = '/img/DownloadApplication/notification.png',
  appStore = '/img/DownloadApplication/appStore.svg',
  googlePlay = '/img/DownloadApplication/googlePlay.svg',
  clock = '/img/DownloadApplication/clock.svg',
  hotWaterCutPageAppDownloadQr = '/img/HotWaterCutPage/appDownloadQr.png',
}

export enum ImageAltTextEnum {
  qrCode = 'Код ссылка на скачивание приложения',
  rustore = 'Магазин приложений - Ру Стор',
  nashstore = 'Магазин приложений - Наш Стор',
  ring = 'Фоновая графика - кольцо на фоне',
  phone = 'Изображение телефона с приложением Платежи Москвы',
  notification = 'Изображение уведомления приложения - Платежи Москвы',
  appStore = 'Магазин приложений - Эпл стор',
  googlePlay = 'Магазин приложений - Гугл плей',
  clock = 'Иконка часов',
}
