import Typograf from '@a3/chameleon/src/components/basic/Typograf';
import { Subtitle1 } from '@a3/chameleon/src/components/basic/Typography';
import cn from 'classnames';
import Image from 'next/image';
import type { FC } from 'react';

import { advantagesCardsData } from 'components/Advantages/data';
import type { AdvantagesTypes } from 'components/Advantages/types';
import { classBlock, analyticsEvent } from 'components/Advantages/types';
import ContentContainer from 'components/basic/ContentContainer';
import DropdownClick from 'components/complex/DropdownClick';

import s from './Advantages.module.scss';

const Advantages: FC<AdvantagesTypes> = ({
  downloadHref,
  analyticsEvt = analyticsEvent,
  className,
  ...restProps
}) => {
  return (
    <Typograf>
      <ContentContainer>
        <div className={cn(s.wrapper, 'grid', className)} {...restProps}>
          {advantagesCardsData.map(({ image, title, alt }) => (
            <DropdownClick
              className="g-col-xss-2 g-col-s-4"
              key={image}
              classBlock={classBlock}
              analyticsEvent={analyticsEvt}
              downloadHref={downloadHref}
            >
              <div className={s.wrapperCard}>
                <Image
                  className={s.image}
                  src={`/img/Advantages/${image}.png`}
                  width={190}
                  height={190}
                  alt={alt}
                />
                <Subtitle1 className={s.subtitle}>{title}</Subtitle1>
              </div>
            </DropdownClick>
          ))}
        </div>
      </ContentContainer>
    </Typograf>
  );
};

export default Advantages;
